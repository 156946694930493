import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { H5, H2, H3 } from "../components/utils/Typography"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { ButtonPrimary } from "../components/utils/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapPin,
  faGlobe,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"
import * as ReactMarkdown from "react-markdown"
import OnDeltaCard from "../components/Website-Blocks/OnDeltaCard"
import Email from "../components/Website-Blocks/Email"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Main = styled.div`
  background-color: #0022e721;
  padding: 50px 20px 50px 20px;
  margin: 60px 0;
  text-align: center;
`

const Tag = styled.div`
  background-color: #0022e721;
  padding: 10px;
  margin: 10px;
`

const Content = styled.div`
  margin: 50px 30px 70px 30px;
`

const AboutContent = styled.div`
  margin: 40px 0;
  font-family: "Nunito Sans", sans-serif;
`

const JobPost = ({ data }) => {
  //Checks if image is null
  function Image() {
    const imageSource = data.airtable.data.Company_Logo
    if (imageSource) {
      return (
        <img
          src={data.airtable.data.Company_Logo[0].url}
          style={{ width: "100px", marginBottom: "40px" }}
          alt="company logo"
        />
      )
    }
    console.log(data.airtable.data.Company_Logo)
    return null
  }

  return (
    <Layout>
      <SEO
        title={`${data.airtable.data.Company_Name} || ${data.airtable.data.Job_Title}`}
      />
      <Container>
        <Content>
          <Row>
            <Col lg="8">
              <Link to="/" style={{ textDecoration: "none" }}>
                <H5 style={{ color: "#0022e7" }}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to Jobs
                </H5>
              </Link>
              <H2 style={{ marginBottom: "10px" }}>
                {data.airtable.data.Job_Title}
              </H2>
              <H5 style={{ marginBottom: "15px" }}>
                Posted on {data.airtable.data.Posting_Date}
              </H5>

              <Row>
                {data.airtable.data.Category.map(type => (
                  <Tag>
                    <span key={type}>
                      <H5 style={{ margin: "0", fontWeight: "600" }}>{type}</H5>
                    </span>
                  </Tag>
                ))}
              </Row>
              <AboutContent>
                <H5 style={{ fontWeight: 700, marginBottom: "10px" }}>
                  Quick Facts:
                </H5>
                <H5>
                  <ul>
                    <li>Experience: {data.airtable.data.Experience_Level}</li>
                    <li>Position: {data.airtable.data.Job_Type}</li>
                    <li>Location: {data.airtable.data.Locations_Allowed}</li>
                  </ul>
                </H5>
              </AboutContent>
              <AboutContent>
                <H5 style={{ fontWeight: 700, marginBottom: "10px" }}>
                  About the opportunity:
                </H5>
                <H5>
                  <ReactMarkdown
                    source={data.airtable.data.About_Opportunity}
                  />
                </H5>
              </AboutContent>
              <AboutContent>
                <H5 style={{ fontWeight: 700, marginBottom: "10px" }}>
                  About the company:
                </H5>
                <H5>
                  <ReactMarkdown source={data.airtable.data.About_Company} />
                </H5>
              </AboutContent>
              <OutboundLink
                href={data.airtable.data.Link_to_Apply}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonPrimary>
                  <H5 style={{ fontWeight: 700, marginBottom: "0" }}>
                    Apply Now!
                  </H5>
                </ButtonPrimary>
              </OutboundLink>
            </Col>
            <Col lg={{ size: "3", offset: "1" }}>
              <Main>
                <Container>
                  <Image />
                  <H3 textAlign="center" style={{ fontWeight: "700" }}>
                    {data.airtable.data.Company_Name}
                  </H3>
                  <H5 textAlign="center" style={{ marginBottom: "10px" }}>
                    <FontAwesomeIcon
                      icon={faMapPin}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    {data.airtable.data.Company_Location}
                  </H5>
                  <H5 textAlign="center">
                    <FontAwesomeIcon
                      icon={faGlobe}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    <a
                      href={data.airtable.data.Company_Location}
                      style={{ color: "#000", textDecoration: "none" }}
                    >
                      Visit Website
                    </a>
                  </H5>
                  <OutboundLink
                    href={data.airtable.data.Link_to_Apply}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonPrimary
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      {" "}
                      Apply Now!
                    </ButtonPrimary>
                  </OutboundLink>
                </Container>
              </Main>
              <OnDeltaCard />
            </Col>
          </Row>
        </Content>
        <Email />
      </Container>
    </Layout>
  )
}
export default JobPost

export const pageQuery = graphql`
  query($recordId: String!) {
    airtable(recordId: { eq: $recordId }) {
      data {
        About_Company
        About_Opportunity
        Company_Name
        Email
        Job_Title
        Experience_Level
        Job_Type
        Link_to_Apply
        Posting_Date
        Expiration_Date
        Remote_Ok_
        Status
        Category
        New
        Featured
        Company_Location
        Company_Website
        Locations_Allowed
        Company_Logo {
          url
        }
      }
      recordId
      id
    }
  }
`
